<template>
    <div type="text/x-template" id="modal-template">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">

                        <div class="container-fluid">
                            <div class="row p-3" style="background-color: #444444">
                                <div class="col-6">
                                    <h3 class="text-white mb-0">Domain Management</h3>
                                </div>
                            </div>
                            <div class="row p-2" style="background-color: #EBEBEB; height: 50px;"></div>
                            <div class="row">
                                <b-table
                                :fields="fields"
                                :items="items"
                                :per-page="perPage"
                                :current-page="currentPage"
                                >
                                    <template v-slot:cell(Action)="row" >
                                        <div>
                                            <span v-if="row.item.status === 'verified'" style="cursor: pointer" @click="diableDom(row.item._id)">Disable</span>
                                            <span  v-if="row.item.status === 'pending' && $props.verifyDomain || row.item.status === 'disabled' && !loading && $props.verifyDomain" style="cursor: pointer" @click="testVerify(row.item._id, row.index)"> Test Verify | </span>
                                            <span v-if="row.item.status === 'pending' && !loading" style="cursor: pointer" @click="verify(row.item._id, row.index)">Verify</span>
                                            <b-icon-three-dots v-if="row.item.status === 'pending' && loading === row.index" animation="cylon" font-scale="1.5"></b-icon-three-dots>
                                            <span v-if="row.item.status === 'disabled'" style="cursor: pointer" @click="enableDom(row.item._id)">Activate</span>
                                            | <span style="color: red; cursor: pointer;" @click="remove(row.item._id, row.index)">Remove</span></div>
                                    </template>
                                </b-table>
                                <div class="col-12 d-flex justify-content-center">
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="items.length"
                                        align="center"
                                        :per-page="perPage">
                                    </b-pagination>
                                </div>
                            </div>
                            <div class="row p-3" style="background-color: #444444">
                                <div class="col-6">
                                    <b-button variant="dark" class="font-weight-bold" @click="$emit('closedm')" style="border-radius: 0px !important;">BACK</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import { BIconThreeDots  } from 'bootstrap-vue'
    import axios from "axios";
    import swal from 'sweetalert'
    export default {
        name: "manage",
        data() {
            return {
                loading: '',
                currentPage: 1,
                perPage: 5,
                items: [],
            }
        },
        props: {
            id: {
                type: String,
                required: true,
            },

            client_id: {
                type: String,
                required: true,
            },

            verifyDomain: {
                type: Boolean,
                required: true,
            }
        },
        components: {
            BIconThreeDots,
        },
        created() {
            this.getDomains()
        },
        computed: {
            fields() {
                return [
                    {
                        key: 'created_ts',
                        label: 'Date',
                        sortable: true
                    },
                    {
                        key: 'name',
                        label: 'Domain',
                        sortable: false
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        sortable: true
                    },
                    {
                        key: 'Action',
                        label: 'Action',
                        sortable: false
                    }
                ]
            },
        },
        methods: {
            getDomains() {
                axios.get(`${this.$root.serverUrl}/admin/domain/domain/list/${this.id}`).then((resp) => {
                    if ( resp.data && !resp.error ) {
                        this.items = resp.data.data || []
                    }
                })
            },

            testVerify(id, index) {
                axios.post(`${this.$root.serverUrl}/admin/domain/testVerify/${id}`).then((resp) => {
                    if ( resp.data && !resp.error ) {
                        this.items[index].status = 'verified'
                    }
                })
            },

            verify(id, index) {
                this.$nextTick(() => { this.loading = index })
                axios.post(`${this.$root.serverUrl}/admin/domain/domain/verify/${id}`).then((resp) => {
                    if ( resp.data && resp.data.error ) {
                        this.loading = false
                        swal({title:"Notice", text: resp.data.error.message, icon:"info"})
                    } else {
                        this.getDomains()
                    }
                })
            },
            remove(id) {
                axios.delete(`${this.$root.serverUrl}/admin/domain/domain/${id}`).then((resp) => {
                    if ( resp.data && !resp.data.error ) {
                        this.getDomains()
                    } else {
                        swal({title:"Notice", text: resp.data.error.message, icon:"info"})
                    }
                })
            },
            diableDom(id) {
                axios.post(`${this.$root.serverUrl}/admin/domain/domain/disable/${id}`).then((resp) => {
                    if ( resp.data && !resp.data.error ) {
                        this.getDomains()
                    } else {
                        swal({title:"Notice", text: resp.data.error.message, icon:"info"})
                    }
                })
            },
            enableDom(id) {
                axios.post(`${this.$root.serverUrl}/admin/domain/domain/enable/${id}`).then((resp) => {
                    if ( resp.data && !resp.data.error ) {
                        this.getDomains()
                    } else {
                        swal({title:"Notice", text: resp.data.error.message, icon:"info"})
                    }
                })
            }
        }
    }
</script>

<style scoped>

    .form-control:focus {
        -webkit-box-shadow: 0 0 0 0rem;
        box-shadow: 0 0 0 0rem;
        border-color: #CED4DA;
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        display: table;
        transition: opacity 0.3s ease;
        -o-transition: opacity 0.3s ease;
        -moz-transition: opacity 0.3s ease;
        -webkit-transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 1000px;
        margin: 0px auto;
        padding: 0px 0px 0px 0px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-default-button {
        float: right;
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-leave-active .modal-container {
        transform: scale(1.1);
        -o-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
    }

</style>
